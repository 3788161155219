body {
  font-size: 18px;
  background-color: #cee2ff;
  height: 100vh;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'KoHo', sans-serif;
  font-weight: 600;
  line-height: 1.618;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: grayscale;
	overflow-x: hidden;
}
.-previous {
  background-color: rgba(255, 217, 0, 0.774);
}
.-next {
  background-color: rgba(255, 217, 0, 0.774);
}
.rt-td{
color: rgb(2, 12, 1);
font-family: 'KoHo', sans-serif;
font-size: 14px;
overflow: hidden;
}
.rt-resizable-header-content {
  color: rgb(2, 12, 1);
  font-family: 'KoHo', sans-serif;
  font-size: 14px;
}